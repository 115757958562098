/* eslint-disable jsx-a11y/img-redundant-alt */
import './Privacy.scss';

function Privacy() {
  return (
    <div>
      <section class="section-about">
        <div class="u-center-text u-margin-bottom-big">
          <h2 class="heading-secondary">Privacy Policy</h2>
        </div>

        <div class="row">
          <p class="paragraph">
            Your privacy is critical to us. This Privacy Policy provides the
            manner your data is collected and used by Nisarga Urja Agro Private
            Limited. The terms of this Privacy Policy should be read carefully
            in consonance with the Terms of Use for this application. By
            accessing and using the application you agree to the collection and
            use of your data by us in the manner provided in this Privacy
            Policy.
          </p>
          <h3 class="heading-tertiary u-margin-bottom-small">
            Collection of Personal Information:
          </h3>
          <p class="paragraph">
            In order to register yourself on the application, you will be
            required to provide the following personally identifiable
            information namely: full name, age, email address, mobile phone
            number, gender, postal address etc. Whether any of such information
            is optional or mandatory is indicated by us at the appropriate
            places. You may opt to not give certain information in case it is
            optional. You may modify or delete your username, password and any
            other personally identifiable information by editing your account
            information. Besides personally identifiable information, we also
            collect information about the pages on the application you
            visit/access, the links you click on the application, the number of
            times you access the page and any such browsing information.
          </p>
          <h3 class="heading-tertiary u-margin-bottom-small">Cookies:</h3>
          <p class="paragraph">
            Our application uses cookies, local tags. Through the use of the
            aforementioned, we collect information about your IP Address,
            preferences etc. when you access our application. Further, we also
            collect information about the location of your device, internet
            service provider information, and information about the webpages you
            visit. You can always disable the use of cookies by clicking the
            disable option through the application. However, the disabling of
            cookies may lead to you not being able to avail / access certain
            features of our application. Therefore, we recommend that you do not
            prevent cookies in order to receive an uninterrupted experience of
            our application.
          </p>
          <h3 class="heading-tertiary u-margin-bottom-small">
            Use and sharing of Information:
          </h3>
          <p class="paragraph">
            You hereby grant us and our affiliates and subsidiaries a
            non-exclusive, irrevocable, perpetual, royalty-free, global license
            to store and use information provided by you on this application for
            our business purposes, for completing your registrations, for
            tracking the demographics and behaviour of users, for internal
            analysis, for providing you with a better user experience, for
            giving personalized advertisements, promotional offers and
            recommendations, resolving any troubleshoot problems, to help
            investigate, prevent or take action regarding unlawful and illegal
            activities, suspected fraud, potential threat to the safety or
            security of any person, violations of the application’s terms of use
            et al. We may share the data / information received / collected by
            us from you with our affiliates and / or subsidiaries. We may
            disclose your information to government authorities and / or any
            third parties under any direction of a court of law and / or in
            order to protect our legal rights. You hereby agree and grant us the
            permission for such use, collection, storage, sharing, transfer,
            analysis of any such data and personally identifiable information as
            per the terms of this policy. We assure you that we will not use
            your financial information for any purpose other than to complete a
            transaction with you. We further assure you that we will not sell or
            share your personal information and will not disclose any of your
            personally identifiable information to third parties without your
            previous consent.
          </p>
          <h3 class="heading-tertiary u-margin-bottom-small">Security:</h3>
          <p class="paragraph">
            To protect your account against unauthorized access, misuse and
            alteration we have in place appropriate physical, electronic and
            managerial procedures. Although we endeavor to safeguard the
            confidentiality of your personally identifiable information,
            transmissions made by means of the Internet cannot be made
            absolutely secure. By using this application, you agree that We will
            have no liability for disclosure of your information due to errors
            in transmission or unauthorized acts of third parties. At your end,
            You agree to take reasonable measures like setting a tough password,
            protecting the same from being leaked to third parties amongst other
            things to maintain the secrecy of your account.
          </p>
          <h3 class="heading-tertiary u-margin-bottom-small">
            Policy Updates:
          </h3>
          <p class="paragraph">
            Nisarga Urja Agro Private Limited shall have the sole right to
            change, modify, update, add or remove any part or whole of this
            Privacy Policy from time to time and your continued use of this
            application shall mean that you have accepted and agreed to the
            revised terms of this Policy. It shall be your responsibility to
            check periodically for revisions.
          </p>
          <h3 class="heading-tertiary u-margin-bottom-small">
            Compliance with Laws:
          </h3>
          <p class="paragraph">
            This Privacy Policy has been made in compliance with the applicable
            laws in India namely the Information Technology Act, 2000 and the
            relevant rules thereunder.
          </p>
          <h3 class="heading-tertiary u-margin-bottom-small">
            Grievance Redressal:
          </h3>
          <p class="paragraph">
            In case of any grievances as regards this Privacy Policy, you may
            write to us at the following address. We will try to take cognizance
            of your complaints within 1 (one) week.
            <br />
            <br />
            Name of Grievance Officer: Ram Patil
            <br />
            Email: director@nisargaurja.com
            <br />
          </p>
        </div>
      </section>
    </div>
  );
}

export default Privacy;
